// A convenience function for initializing Sentry on the client and server
/* eslint-disable no-underscore-dangle */
// We need the eslint-disable rule because of the JSON structure and being able to use __dirname for our stack tracing root

import getConfig from 'next/config';
import { init } from '@sentry/nextjs';

const {
  publicRuntimeConfig: { SENTRY_APP_RELEASE, SENTRY_DSN, SENTRY_ENV, SENTRY_ENABLED },
} = getConfig();

const defaultConfig = {
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  release: SENTRY_APP_RELEASE,
};

export default function initSentry(config = {}) {
  if (SENTRY_ENABLED === 'true') {
    init({
      ...defaultConfig,
      ...config,
      ignoreErrors: [
        /Blocked a frame with origin "https:\/\/www\.care\.com" from accessing a cross-origin frame\. Protocols, domains, and ports must match/,
      ],
      beforeSend: (event) => {
        const userAgent = event?.request?.headers?.['User-Agent'];

        if (userAgent?.toLowerCase().includes('headlesschrome')) {
          // Return null if the User-Agent indicates "headlesschrome"
          return null;
        }

        return event;
      },
    });
  }
}
